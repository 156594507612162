<template>
	<div id="app">
		<!-- <div>
			<Card :state="state" :color="color"></Card>
		</div> -->
		<!-- <div class="sidebar">
			<List ></List>
		</div> -->
		<div class="main">
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
	import List from '../components/list.vue'
	
	export default {
		components: {
			List
		},
		data() {
			return {
				

			}
		},
		created() {

		},
		computed: {

		},
		mounted() {
			//this.$router.push('/all')
			//获取时间
			//this.timeData = this.time()
			//判断是否有记录
			if (localStorage.getItem('state') != null) {
				this.state = JSON.parse(localStorage.getItem('state'))
			}
			//console.log(localStorage.getItem('color'))
			if(localStorage.getItem('color1')){
				this.color = localStorage.getItem('color1')
				console.log(this.color)
			}else{
				this.color = 'black'
			}
			
		},
		methods: {
			listid(e){
				this.list_id = e
			},
		},
	}
</script>

<style lang="less" scoped>
	#app {
		margin: 0px auto;
		width: 100vw;
		height: 100vh;
		background-color: #030516;
		overflow: hidden;
		border-radius: 3px;
		display: flex;
		justify-content: space-between;
		.main {
			width: 76vw;
			height: 100vh;
			//margin-top: 3vh;
			//margin-right: 2vw;
			//background-color: #0E1340;
			border-radius: 20px;
		}
	}
</style>
