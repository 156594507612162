<template>
  <div id="app">
	<router-view></router-view>
  </div>
</template>

<script>
import DevicePixelRatio from '@/utils/devicePixelRatio'
export default {
  name: 'App',
  created() {
  	//this.scale();
  	//document.documentElement.style.zoom = '80%'
  	new DevicePixelRatio().init()
  	
  }
}
</script>
<style lang="less">
	// html,body,h3{
	// 	margin: 0;
	// 	padding: 0;
	// }
	* {
		list-style: none;
		margin: 0;
		padding: 0;
	}
</style>
