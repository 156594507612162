<template>
	<div>
		<!-- pc -->
		<div class="pc" style="position: relative;">
			<div style="height: 50px;"></div>
			<div style="width: 16vw;margin: 0 auto;">
				<!-- <img style="width: 16vw;height: 8vw;" src="../assets/image/kuang.png" alt="" srcset="" /> -->
				<div style="color: #fff;width: 16vw;display: flex;align-items: center;justify-content: center;top: 0;">
					<img src="../assets/image/1@2x.png" alt="" srcset="" />
					<div style="font-size: 21px;margin-left: 10px;">人工智能产业学院</div>
					<!-- <div style="font-size: 24px;font-weight: 700;">石家庄学院</div>
					<div style="margin-top: 5px;font-size: 18px;">人工智能产业学院</div> -->
				</div>
			</div>

			<img style="width: auto;height: 35vh;margin-top: 60px;z-index: 9999;" src="../assets/image/5.gif" alt=""
				srcset="" />
			<!-- <img src="../assets/image/0.png" style="position: absolute;top: 350px;width: 14vw;right: 3.5vw;z-index: 1;" alt="" srcset="" /> -->
			<div style="color: #fff;font-size: 26px;margin-top: 60px;">催债</div>
			<div style="color: #fff;font-size: 18px;margin-top: 20px;padding: 0 25px;">这款法律问答产品是一位专业、全面的法律智能助手。具备深厚的法律知识，该产品致力于解答各类法律问题，用户可以通过文字和语音与该法律助手进行交互，以便获得即时、准确的法律解答，同时还可以通过语音或者文字描述让智能助手帮您生成图片。这款产品不仅提供清晰易懂的法律解释，还通过其先进的语音识别技术，使用户能够通过口头提问获取所需信息。该法律助手的全天候服务确保用户无论身处何地，都能方便地获得法律咨询和指导</div>
			<!-- <div style="color: #fff;font-size: 18px;margin-top: 20px;padding: 0 25px;" v-if="title == '文生图'">文生图产品是一款创新性的工具，具备先进的图像生成算法，它能够根据输入的文字内容自动生成对应的图片，无论是描述场景、描绘人物还是阐释概念，文生图都能以图像形式呈现，为文字赋予更直观、生动的表达，支持多种风格，包括卡通、油画、水彩等，让用户在表达时具备更大的创意空间，同时为用户提供了一种全新的图文表达方式</div> -->
			<!-- <div style="color: #fff;font-size: 18px;margin-top: 10px;">请问有什么法律问题可以帮助您？</div> -->
		</div>
	</div>

</template>

<script>
	let goAnimate = ''
	import '../assets/icon/iconfont.css'
	import bus from '../config/bus.js'
	export default {
		components: {},
		data() {
			return {
				currentId: 1,
				list_id: '1', //选中的列表
				moves: true,
				mobile: '',
				isCollapse: false,
				input: '',
				isCollapse: false,
				toggle: false, //第二个图标默认隐藏
				block: true, //默认显示第一个图标
				title:'法律问答'
			}
		},
		created() {
			bus.$on("hello", (data) => {
				console.log(data);
				this.title = data
			});
		},
		mounted() {

		},
		methods: {

		},
	}
</script>

<style scoped lang="less">
	.pc {
		width: 20vw;
		height: 100vh;
		background-image: url(../assets/image/bc.png);
		background-repeat: no-repeat;
		//保证盒子始终在窗口中心
		background-position: center center;
		text-align: center;
	}
</style>