import axios from "axios"
import Vue from 'vue'
let vue = new Vue()
const instance1 = axios.create({
	//baseURL: 'http://124.239.143.118:20005',//正式
	//baseURL: 'httphttp://192.168.0.115:5090',//测试
	baseURL: "http://192.168.1.8:2424",
	timeout: 50000, //请求超时
	responseType: 'blob', // 申明为文件流

});
//instance1.defaults.headers.common['x-requested-with'] = window.localStorage.getItem('token')
export {
	instance1
}