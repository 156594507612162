<template>
	<div>
		<div v-if="show">
			<div class="showbackground">
				<div style="height: 128px;"></div>
				<div style="width: 100%;text-align: center;">
					<img @click="clearCountdown" style="" src="../assets/image/wrap11.png" alt="" />
				</div>
				<!-- <div style="font-size: 100px;color: #fff;text-align: center;" @click="clearCountdown">智能AI客服服务系统</div> -->
				<div style="text-align: center;">
					<img src="../assets/image/huxian.png" alt="" srcset="" />
				</div>
				<div style="display: flex;justify-content: space-between;margin: 0 194px;margin-top: 50px;">
					<div style="position: relative;width: 435px;height: 443px;">
						<div style="display: flex;flex-direction: column;position: absolute;right: 52px;top: -13px;">
							<img v-if='current1 >= 0 && current1 <= 2' src="../assets/image/wrap10.png" alt="" />
							<img v-if='current1 >= 1 && current1 <= 2' src="../assets/image/wrap9.png" alt="" />
							<img v-if='current1 == 2' src="../assets/image/wrap8.png" alt="" />
						</div>
						<img style="position: absolute;" src="../assets/image/wrap3.png" alt="" srcset="" />
						<div style="width: 271px;margin: 0 auto;">
							<img style="margin-top: 62px;" src="../assets/image/wrap5.png" alt="" />
							<div
								style="font-weight: 500;font-size: 18px;color: #BECAFF;line-height: 38px;margin-top: 39px;">
								基于自然语言处理(NLP)技术的AI智能法律服务系统，可以将语音转换为文本，并通过理解文本内容精准解答法律疑问，以人机对话的形式为我们提供专业法律指导。</div>
						</div>
					</div>
					<div style="position: relative;width: 435px;height: 443px;">
						<div style="display: flex;flex-direction: column;position: absolute;right: 52px;top: -13px;">
							<img v-if='current1 >= 0 && current1 <= 2' src="../assets/image/wrap10.png" alt="" />
							<img v-if='current1 >= 1 && current1 <= 2' src="../assets/image/wrap9.png" alt="" />
							<img v-if='current1 == 2' src="../assets/image/wrap8.png" alt="" />
						</div>
						<img style="position: absolute;" src="../assets/image/wrap3.png" alt="" srcset="" />
						<div style="width: 271px;margin: 0 auto;">
							<img style="margin-top: 62px;" src="../assets/image/wrap7.png" alt="" />
							<div
								style="font-weight: 500;font-size: 18px;color: #BECAFF;line-height: 38px;margin-top: 39px;">
								智能外呼系统通过自然语言处理技术，可以理解和回应债务人的问题和疑虑，系统可以自动识别债务人的意图，并提供准确的回答和建议。</div>
						</div>
					</div>
					<div style="position: relative;width: 435px;height: 443px;">
						<div style="display: flex;flex-direction: column;position: absolute;right: 52px;top: -13px;">
							<img v-if='current1 >= 0 && current1 <= 2' src="../assets/image/wrap10.png" alt="" />
							<img v-if='current1 >= 1 && current1 <= 2' src="../assets/image/wrap9.png" alt="" />
							<img v-if='current1 == 2' src="../assets/image/wrap8.png" alt="" />
						</div>
						<img style="position: absolute;" src="../assets/image/wrap3.png" alt="" srcset="" />
						<div style="width: 271px;margin: 0 auto;">
							<img style="margin-top: 62px;" src="../assets/image/wrap6.png" alt="" />
							<div
								style="font-weight: 500;font-size: 18px;color: #BECAFF;line-height: 38px;margin-top: 39px;">
								基于自然语言处理（NLP）技术的智能内训系统，化身为一个智能对话伙伴，能够以自然、流畅的语言与用户进行互动，解答金融领域的各种问题。</div>
						</div>
					</div>
				</div>
				<div style="text-align: center;margin-top: 33px;">
					<img src="../assets/image/wrap4.png" alt="" srcset="" />
					<div style="display: flex;justify-content: center;align-items: center;margin-top: 16px;">
						<img src="../assets/image/wrap2.png" alt="" />
						<div style="color: #fff;margin-left: 5px;">扫码体验</div>
					</div>
				</div>
			</div>
		</div>
		<div style="display: flex;" v-if="!show">
			<!-- <audio src="video_url" controls="controls" ref="audio"></audio> -->
			<video style="width: 1px;height: 1px;" ref="videoPlayer" playsinline id="videoPlayer" class="video"
				width="100%" autoplay :muted="false" controls :src="video_url">
			</video>
			<img style="position: absolute;left: -30px;top: -10px;" src="../assets/image/logo.png" alt="" srcset="" />
			<div class="pc" v-if="current == 0">
				<div style="height: 150px;width: 22vw;"></div>
				<div style="position: relative;width: 22vw;height: 573px;">
					<div style="display: flex;flex-direction: column;position: absolute;right: 52px;top: -13px;">
						<img v-if='current1 >= 0 && current1 <= 2' src="../assets/image/wrap10.png" alt="" />
						<img v-if='current1 >= 1 && current1 <= 2' src="../assets/image/wrap9.png" alt="" />
						<img v-if='current1 == 2' src="../assets/image/wrap8.png" alt="" />
					</div>
					<img style="position: absolute;top: 0;left: 0;width: 20vw;margin-left: 1vw;"
						src="../assets/image/index3.png" alt="" srcset="" />
					<div style="width: 271px;margin: 0 auto;">
						<img style="margin-top: 62px;" src="../assets/image/wrap5.png" alt="" />
						<div
							style="font-weight: 500;font-size: 18px;color: #BECAFF;line-height: 38px;margin-top: 39px;">
							基于自然语言处理(NLP)技术的AI智能法律服务系统，可以将语音转换为文本，并通过理解文本内容精准解答法律疑问，以人机对话的形式为我们提供专业法律指导。</div>
					</div>
					<img style="width: 100px;height: 100px;margin-top: 29px;" src="../assets/image/wrap4.png" alt="" />
					<div style="color: #fff;margin-top: 20px;">扫码体验</div>
				</div>
				<div style="color: #fff;margin-top: 75px;">{{count}}S后自动结束体验</div>
				<div @click="clearCountdown"
					style="width: 322px;height: 89px;background: #192067;border-radius: 15px;margin: 0 auto;margin-top: 35px;display: flex;align-items: center;justify-content: center;">
					<img src="../assets/image/发送.png" alt="" srcset="" />
					<div style="font-weight: 500;font-size: 20px;color: #D1D6FF;">提前结束</div>
				</div>
			</div>
			<div class="pc" v-if="current == 1">
				<div style="height: 150px;width: 22vw;"></div>
				<div style="position: relative;width: 22vw;height: 573px;">
					<div style="display: flex;flex-direction: column;position: absolute;right: 52px;top: -13px;">
						<img v-if='current1 >= 0 && current1 <= 2' src="../assets/image/wrap10.png" alt="" />
						<img v-if='current1 >= 1 && current1 <= 2' src="../assets/image/wrap9.png" alt="" />
						<img v-if='current1 == 2' src="../assets/image/wrap8.png" alt="" />
					</div>
					<img style="position: absolute;top: 0;left: 0;width: 20vw;margin-left: 1vw;"
						src="../assets/image/index3.png" alt="" srcset="" />
					<div style="margin: 0 20px;">
						<div
							style="color: #D1D6FF;display: flex;justify-content: space-between;align-items: center;margin: 0 43px;padding-top: 80px;box-sizing: border-box;">
							<div style="font-size: 24px;">{{list11.name}}</div>
							<div
								style="width: 106px;height: 42px;border-radius: 36px;font-size: 17px;border: 1px solid #383D68;line-height: 42px;">
								华夏银行</div>
						</div>
						<div style="margin-top: 43px;">
							<div style="display: flex;align-items: center;margin-left: 43px;margin-top: 15px;">
								<img style="width: 26px;height: 26px;" src="../assets/image/1.png" alt="" srcset="" />
								<div style="font-size: 18px;color: #fff;margin-left: 12px;">逾期：{{list11.overdue_time}} -
									{{list11.overdue_money}}元 </div>
							</div>
							<div style="display: flex;align-items: center;margin-left: 43px;margin-top: 15px;">
								<img style="width: 26px;height: 26px;" src="../assets/image/2.png" alt="" srcset="" />
								<div style="font-size: 18px;color: #fff;margin-left: 12px;">
									最后还款日：{{list11.final_redate}}</div>
							</div>
							<div style="display: flex;align-items: center;margin-left: 43px;margin-top: 15px;">
								<img style="width: 26px;height: 26px;" src="../assets/image/3.png" alt="" srcset="" />
								<div style="font-size: 18px;color: #fff;margin-left: 12px;">后果：{{list11.overdue_conse}}
								</div>
							</div>
							<div style="display: flex;align-items: center;margin-left: 43px;margin-top: 15px;">
								<img style="width: 26px;height: 26px;" src="../assets/image/5.png" alt="" srcset="" />
								<div style="font-size: 18px;color: #fff;margin-left: 12px;">卡号：{{list11.overdue_card}}
								</div>
							</div>
							<div style="display: flex;align-items: center;margin-left: 43px;margin-top: 15px;">
								<img style="width: 26px;height: 26px;" src="../assets/image/4.png" alt="" srcset="" />
								<div style="font-size: 18px;color: #fff;margin-left: 12px;">还款方式：{{list11.huan_fang}}
								</div>
							</div>
							<img style="width: 100px;height: 100px;margin-top: 29px;" src="../assets/image/wrap4.png"
								alt="" />
							<div style="color: #fff;margin-top: 20px;">扫码体验</div>
						</div>
					</div>
					<!-- <div style="width: 271px;margin: 0 auto;">
						<img style="margin-top: 62px;" src="../assets/image/wrap7.png" alt="" />
						<div
							style="font-weight: 500;font-size: 18px;color: #BECAFF;line-height: 38px;margin-top: 39px;">
							AI智能催债系统通过自然语言处理技术，可以理解和回应债务人的问题和疑虑，系统可以自动识别债务人的意图，并提供准确的回答和建议。</div>
					</div>
					<img style="width: 100px;height: 100px;margin-top: 29px;" src="../assets/image/wrap4.png" alt="" />
					<div style="color: #fff;margin-top: 20px;">扫码体验</div> -->
				</div>
				<div style="color: #fff;margin-top: 75px;">{{count}}S后自动结束体验</div>
				<div @click="clearCountdown"
					style="width: 322px;height: 89px;background: #192067;border-radius: 15px;margin: 0 auto;margin-top: 35px;display: flex;align-items: center;justify-content: center;">
					<img src="../assets/image/发送.png" alt="" srcset="" />
					<div style="font-weight: 500;font-size: 20px;color: #D1D6FF;">提前结束</div>
				</div>
			</div>
			<div class="pc" v-if="current == 2">
				<div style="height: 150px;width: 22vw;"></div>
				<div style="position: relative;width: 22vw;height: 573px;">
					<div style="display: flex;flex-direction: column;position: absolute;right: 52px;top: -13px;">
						<img v-if='current1 >= 0 && current1 <= 2' src="../assets/image/wrap10.png" alt="" />
						<img v-if='current1 >= 1 && current1 <= 2' src="../assets/image/wrap9.png" alt="" />
						<img v-if='current1 == 2' src="../assets/image/wrap8.png" alt="" />
					</div>
					<img style="position: absolute;top: 0;left: 0;width: 20vw;margin-left: 1vw;"
						src="../assets/image/index3.png" alt="" srcset="" />
					<div style="width: 271px;margin: 0 auto;">
						<img style="margin-top: 62px;" src="../assets/image/wrap6.png" alt="" />
						<div
							style="font-weight: 500;font-size: 18px;color: #BECAFF;line-height: 38px;margin-top: 39px;">
							基于自然语言处理（NLP）技术的智能内训系统，化身为一个智能对话伙伴，能够以自然、流畅的语言与用户进行互动，解答金融领域的各种问题。</div>
					</div>
					<img style="width: 100px;height: 100px;margin-top: 29px;" src="../assets/image/wrap4.png" alt="" />
					<div style="color: #fff;margin-top: 20px;">扫码体验</div>
				</div>
				<div style="color: #fff;margin-top: 75px;">{{count}}S后自动结束体验</div>
				<div @click="clearCountdown"
					style="width: 322px;height: 89px;background: #192067;border-radius: 15px;margin: 0 auto;margin-top: 35px;display: flex;align-items: center;justify-content: center;">
					<img src="../assets/image/发送.png" alt="" srcset="" />
					<div style="font-weight: 500;font-size: 20px;color: #D1D6FF;">提前结束</div>
				</div>
			</div>
			<div style="margin-left: 2vw;">
				<div
					style="height: 10vh;width: 74vw;color: #fff;display: flex;justify-content: space-between;align-items: center;">
					<!-- <img style="width: 5vh;height: 5vh;" src="../assets/image/touxiang2.png" alt="" srcset="" /> -->
					<div style="width: 500px;height: 10vh;display: flex;">
						<div v-for="(item,index) in title_list"
							style="position: relative;width: 170px;height: 10vh;display: flex;align-items: center;justify-content: center;"
							@click="qiehuan(index)">
							<div style="font-size: 24px;position: absolute;bottom: 33px;">{{item.name}}</div>
							<img v-if="index == current"
								style="width: 60px;height: 50px;position: absolute;bottom: -16px;"
								src="../assets/image/jiantou.png" alt="" srcset="" />
						</div>
					</div>
					<div @click="qingkong" class="qingkong" style="">
						<img style="width: 21px;height: 21px;margin-right: 10px;" src="../assets/image/shanchu.png"
							alt="" />
						<div style="font-size: 18px;">清空本页</div>
					</div>
					<!-- <button @click="play">dianji</button> -->
				</div>
				<div class="message1">
					<div class="message" ref="QAContent">
						<ul v-if="current === 0" style="padding: 20px 0;">
							<li v-for="item in all.sessions.message">
								<p class="time" style="color: #fff;font-size: 18px;">{{item.date}}</p>
								<div v-if="item.self2 == true" class="main">
									<img class="avatar" width="60" height="60" style="border-radius: 50px;"
										:src="item.self1 ? all.user.img : all.sessions.user.img" />
									<div class="text" v-if="item.self">
										<div v-if="!item.dian" style="white-space: pre-wrap;font-size: 26px;">
											{{item.content}}
										</div>
										<div v-if="item.dian " style="white-space: pre-wrap;font-size: 26px;">
											<span class="dotting"></span>
										</div>
									</div>
									<!-- http://124.239.143.118:20009/excelup/banner.mp4 -->
									<!-- <video v-if="!item.self"
										src="http://123.56.12.238//shujuku/zhihuishitang/tiyu/702889979.mp4"
										class="bann-video" autoplay="autoplay" loop muted="false"></video> -->
								</div>
								<!-- <div v-if="item.self1 == true" class="main" :class="{ self: item.self1 }" v_viewer>
									<img class="avatar" width="40" height="40" style="border-radius: 50px;"
										:src="item.self1 ? all.user.img : all.sessions.user.img" />
									<div class="text" v-if="item.self">
										<div style="white-space: pre-wrap;font-size: 10px;">{{item.content}}</div>
									</div>
									<img class="avatar1" v-if="!item.self" :src="item.content" />
									
								</div> -->
								<div v-if="item.self2 == false" class="main" :class="{ self: item.self1 }" v_viewer>
									<div class="text" v-if="item.self">
										<div style="white-space: pre-wrap;font-size: 26px;">{{item.content}}</div>
									</div>
									<el-image style="width: auto;height: 500px;border-radius: 20px;" :src="item.content"
										v-if="!item.self" :preview-src-list="aaaa">
									</el-image>
									<!-- <img class="avatar1" v-if="!item.self" :src="item.content" /> -->
									<img class="avatar" width="60" height="60" style="border-radius: 50px;"
										:src="item.self1 ? all.user.img : all.sessions.user.img" />
								</div>
							</li>
						</ul>
						<ul v-if="current === 1">
							<li v-for="item in all1.sessions.message">
								<p class="time" style="color: #fff;font-size: 18px;">{{item.date}}</p>
								<div v-if="item.self1 == false" class="main">
									<img class="avatar" width="60" height="60" style="border-radius: 50px;"
										:src="item.self1 ? all.user.img : all.sessions.user.img" />
									<div class="text" v-if="item.self">
										<div v-if="!item.dian" style="white-space: pre-wrap;font-size: 26px;">
											{{item.content}}
										</div>
										<div v-if="item.dian " style="white-space: pre-wrap;font-size: 26px;">
											<span class="dotting"></span>
										</div>
									</div>
									<!-- <video v-if="!item.self" src="http://124.239.143.118:20009/excelup/banner.mp4"
										class="bann-video" autoplay="autoplay" loop muted="false"></video> -->
								</div>
								<div v-if="item.self1 == true" class="main" :class="{ self: item.self1 }" v_viewer>
									<!-- <div style="min-width: 700px;border: 1px #fff solid;display: flex;"> -->
									<div class="text" v-if="item.self">
										<div style="white-space: pre-wrap;font-size: 26px;">{{item.content}}</div>
									</div>
									<img class="avatar" width="60" height="60" style="border-radius: 50px;"
										:src="item.self1 ? all.user.img : all.sessions.user.img" />
									<!-- </div> -->
								</div>
							</li>
						</ul>
						<ul v-if="current === 2" style="padding: 20px 0;">
							<li v-for="item in all2.sessions.message">
								<p class="time" style="color: #fff;font-size: 18px;">{{item.date}}</p>
								<div v-if="item.self2 == true" class="main">
									<img class="avatar" width="60" height="60" style="border-radius: 50px;"
										:src="item.self1 ? all2.user.img : all2.sessions.user.img" />
									<div class="text" v-if="item.self">
										<div v-if="!item.dian" style="white-space: pre-wrap;font-size: 26px;">
											{{item.content}}
										</div>
										<div v-if="item.dian " style="white-space: pre-wrap;font-size: 26px;">
											<span class="dotting"></span>
										</div>
									</div>
									<!-- http://124.239.143.118:20009/excelup/banner.mp4 -->
									<!-- <video v-if="!item.self"
										src="http://123.56.12.238//shujuku/zhihuishitang/tiyu/702889979.mp4"
										class="bann-video" autoplay="autoplay" loop muted="false"></video> -->
								</div>
								<!-- <div v-if="item.self1 == true" class="main" :class="{ self: item.self1 }" v_viewer>
									<img class="avatar" width="40" height="40" style="border-radius: 50px;"
										:src="item.self1 ? all.user.img : all.sessions.user.img" />
									<div class="text" v-if="item.self">
										<div style="white-space: pre-wrap;font-size: 10px;">{{item.content}}</div>
									</div>
									<img class="avatar1" v-if="!item.self" :src="item.content" />
									
								</div> -->
								<div v-if="item.self2 == false" class="main" :class="{ self: item.self1 }" v_viewer>
									<div class="text" v-if="item.self">
										<div style="white-space: pre-wrap;font-size: 26px;">{{item.content}}</div>
									</div>
									<el-image style="width: auto;height: 500px;border-radius: 20px;" :src="item.content"
										v-if="!item.self" :preview-src-list="aaaa">
									</el-image>
									<!-- <img class="avatar1" v-if="!item.self" :src="item.content" /> -->
									<img class="avatar" width="60" height="60" style="border-radius: 50px;"
										:src="item.self1 ? all2.user.img : all2.sessions.user.img" />
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import io from 'socket.io-client'
	import bus from '../config/bus.js'
	import Speech from 'speak-tts'
	export default {
		components: {
			//inc_imgsvg
		},
		props: {
			//all: Object,
		},
		data() {
			return {
				count: 180,
				video_url: "",
				message_last: "",
				current1: 0,
				list11: {},
				listall: [{
					img: require('../assets/image/1.png'),
					text: '逾期：两期-5000元'
				}, {
					img: require('../assets/image/2.png'),
					text: '最后还款日：9月30日'
				}, {
					img: require('../assets/image/3.png'),
					text: '后果：信用卡冻结'
				}, {
					img: require('../assets/image/5.png'),
					text: '卡号：1234567890123456'
				}, {
					img: require('../assets/image/4.png'),
					text: '还款方式：微信还款'
				}],
				show: true,
				aaaa: [],
				current: 0,
				title_list: [{
					name: '法律问答'
				}, {
					name: '智能外呼'
				}, {
					name: '智能内训'
				}],
				timerId: null,
				speech: null,
				socket: '',
				ws1: null,
				list_id: "1", //选中的列表
				dialogVisible: false, //对话框的显示隐藏
				imageUrl: '',
				mobile: '',
				list: [],
				index2: 0,
				all: {
					user: {
						name: 'coffce',
						img: require("../assets/image/touxiang2.png"),
					},
					sessions: {
						id: "25",
						user: {
							name: '功能体验，请在扫描二维码进行操作~',
							img: require("../assets/image/touxiang1.png"),
						},
						message: [{
							content: '法律问答功能体验，请在扫描二维码进行操作~',
							self: true,
							self1: false,
							dian: false,
							self2: false,
							//date: this.time()
						}]
					}
				},
				all1: {
					user: {
						name: 'coffce',
						img: require("../assets/image/touxiang2.png"),
					},
					sessions: {
						id: "25",
						user: {
							name: '智能外呼功能体验，请在扫描二维码进行操作~',
							img: require("../assets/image/touxiang1.png"),
						},
						message: []
					}
				},
				all2: {
					user: {
						name: 'coffce',
						img: require("../assets/image/touxiang2.png"),
					},
					sessions: {
						id: "25",
						user: {
							name: '功能体验，请在扫描二维码进行操作~',
							img: require("../assets/image/touxiang1.png"),
						},
						message: [{
							content: '智能内训功能体验，请在扫描二维码进行操作~',
							self: true,
							self1: false,
							dian: false,
							self2: false,
							date: this.time()
						}]
					}
				},
			}
		},
		created() {},
		watch: {
			show(newVal, oldVal) {
				if (newVal !== oldVal) {
					if (newVal) {
						console.log('myValue is true');
					} else {
						this.startCountdown()
						console.log('myValue is false');
					}
				}
			}
		},
		mounted() {
			setInterval(() => {
				if (this.current1 >= 2) {
					this.current1 = 0
				} else {
					this.current1 = this.current1 + 1
				}
			}, 500)
			this.yuyin()
			this.scrollToBottom();
			this.webs()
		},
		beforeDestroy() {
			clearInterval(this.timerId); // 清除计时器
		},
		//离开页面取消语音，不取消可能会造成奇妙的bug
		destroyed() {
			this.socket.disconnect()
			this.speech.cancel();
		},
		methods: {
			// end(){
			// 	this.clearCountdown();
			// },
			startCountdown() {
				this.timer = setInterval(() => {
					if (this.count > 0) {
						this.count -= 1;
					} else {
						this.clearCountdown();
					}
				}, 1000);
			},
			clearCountdown() {
				this.video_url = ''
				var params1 = {
					gins: 0
				}
				this.$axios1.post('/up_gins', params1).then(function(res) {
					console.log(res)
				})
				var params = {}
				this.$axios1.post('/close', params).then(function(res) {
					console.log(res)
				})
				if (this.timer) {
					var params = {}
					this.show = true
					this.count = 180
					clearInterval(this.timer);
					this.timer = null;
				}
				this.all = {
					user: {
						name: 'coffce',
						img: require("../assets/image/touxiang2.png"),
					},
					sessions: {
						id: "25",
						user: {
							name: '功能体验，请在扫描二维码进行操作~',
							img: require("../assets/image/touxiang1.png"),
						},
						message: [{
							content: '法律问答功能体验，请在扫描二维码进行操作~',
							self: true,
							self1: false,
							dian: false,
							self2: false,
							//date: this.time()
						}]
					}
				}
				this.scrollToBottom()
				this.all1 = {
						user: {
							name: 'coffce',
							img: require("../assets/image/touxiang2.png"),
						},
						sessions: {
							id: "25",
							user: {
								name: '催债功能体验，请在扫描二维码进行操作~',
								img: require("../assets/image/touxiang1.png"),
							},
							message: []
						}
					},
					this.scrollToBottom()
				this.all2 = {
					user: {
						name: 'coffce',
						img: require("../assets/image/touxiang2.png"),
					},
					sessions: {
						id: "25",
						user: {
							name: '功能体验，请在扫描二维码进行操作~',
							img: require("../assets/image/touxiang1.png"),
						},
						message: [{
							content: '金融培训体验，请在扫描二维码进行操作~',
							self: true,
							self1: false,
							dian: false,
							self2: false,
							date: this.time()
						}]
					}
				}
			},
			async yuyin() {
				try {
					const stream = await navigator.mediaDevices.getUserMedia({
						audio: true
					});
					// 权限被允许，可以使用stream进行音频录制等操作
					console.log('麦克风权限被授予');
				} catch (err) {
					// 权限被拒绝，处理错误
					console.error('无法获取麦克风权限', err);
				}
			},
			scale() {
				var devicewidth = document.documentElement.clientWidth; //获取当前分辨率下的可是区域宽度
				var scale = devicewidth / 1920; // 分母——设计稿的尺寸
				document.body.style.zoom = scale; //放大缩小相应倍数
			},
			qingkong() {
				if (this.current === 0) {
					this.all = {
						user: {
							name: 'coffce',
							img: require("../assets/image/touxiang2.png"),
						},
						sessions: {
							id: "25",
							user: {
								name: '功能体验，请在扫描二维码进行操作~',
								img: require("../assets/image/touxiang1.png"),
							},
							message: [{
								content: '法律问答功能体验，请在扫描二维码进行操作~',
								self: true,
								self1: false,
								dian: false,
								self2: false,
								//date: this.time()
							}]
						}
					}
					this.scrollToBottom()
				} else if (this.current === 1) {
					this.all1 = {
							user: {
								name: 'coffce',
								img: require("../assets/image/touxiang2.png"),
							},
							sessions: {
								id: "25",
								user: {
									name: '催债功能体验，请在扫描二维码进行操作~',
									img: require("../assets/image/touxiang1.png"),
								},
								message: []
							}
						},
						this.scrollToBottom()
				} else if (this.current === 2) {
					this.all2 = {
						user: {
							name: 'coffce',
							img: require("../assets/image/touxiang2.png"),
						},
						sessions: {
							id: "25",
							user: {
								name: '功能体验，请在扫描二维码进行操作~',
								img: require("../assets/image/touxiang1.png"),
							},
							message: [{
								content: '金融培训体验，请在扫描二维码进行操作~',
								self: true,
								self1: false,
								dian: false,
								self2: false,
								date: this.time()
							}]
						}
					}
				}
			},
			qiehuan(index) {
				this.current = index
				if (index === 0) {
					bus.$emit("hello", '文生图');
					this.scrollToBottom()
				} else if (index === 1) {
					bus.$emit("hello", '法律问答');
					this.scrollToBottom()
				}
			},
			aaa() {
				this.socket.on('disconnect', () => console.log('Socket.IO连接已断开'));
			},
			text_voice(e) {
				var _this = this
				var params = {
					text: e,
					speaker: "tianmei_jieshuo",
					length_scale: 1.2,
					max_length: 30,
					max_threads: 10
				}
				this.$axios.post('/bert_vits', params).then(function(res) {
					console.log(res)
					const blob = new Blob([res.data], {
						type: 'audio/wav'
					});
					// 创建一个URL指向这个对象
					const url = URL.createObjectURL(blob);
					console.log(JSON.stringify(url))
					//_this.$refs.videoPlayer.src = url
					_this.video_url = url
					// _this.$refs.audio.currentTime = 0
					// _this.$refs.audio.play()
					// _this.$nextTick(() => {
					// 	_this.$refs.videoPlayer.muted = false;
					// })
					if (_this.all.sessions.message[_this.all.sessions.message.length - 1].content == '...') {
						_this.all.sessions.message.splice(_this.all.sessions.message.length - 1, 1)
					}
					var msg = {
						content: '法律问答\n' + e,
						self: true,
						self1: false,
						dian: false,
						self2: false,
					}
					_this.all.sessions.message.push(msg)
					_this.scrollToBottom()
				})
			},
			text_voice1(e) {
				var _this = this
				var params = {
					text: e,
					speaker: "tianmei_jieshuo",
					length_scale: 1.2,
					max_length: 30,
					max_threads: 10
				}
				this.$axios.post('/bert_vits', params).then(function(res) {
					console.log(res)
					// const binaryData = new Uint8Array([res.data]);
					const blob = new Blob([res.data], {
						type: 'audio/wav'
					});
					// 创建一个URL指向这个对象
					const url = URL.createObjectURL(blob);
					console.log(JSON.stringify(url))
					//_this.$refs.videoPlayer.src = url
					_this.video_url = url
					// _this.$nextTick(() => {
					// 	_this.$refs.videoPlayer.muted = false;
					// })
				})
			},
			text_voice2(e) {
				var _this = this
				var params = {
					text: e,
					speaker: "tianmei_jieshuo",
					length_scale: 1.2,
					max_length: 30,
					max_threads: 10
				}
				this.$axios.post('/bert_vits', params).then(function(res) {
					console.log(res)
					// const binaryData = new Uint8Array([res.data]);
					const blob = new Blob([res.data], {
						type: 'audio/wav'
					});
					// 创建一个URL指向这个对象
					const url = URL.createObjectURL(blob);
					console.log(JSON.stringify(url))
					//_this.$refs.videoPlayer.src = url
					_this.video_url = url
					// _this.$nextTick(() => {
					// 	_this.$refs.videoPlayer.muted = false;
					// })
					if (_this.all2.sessions.message[_this.all2.sessions.message.length - 1].content == '...') {
						_this.all2.sessions.message.splice(_this.all2.sessions.message.length - 1, 1)
					}
					var msg = {
						content: '法律问答\n' + e,
						self: true,
						self1: false,
						dian: false,
						self2: false,
					}
					_this.all2.sessions.message.push(msg)
					_this.scrollToBottom()
				})
			},
			webs() {
				this.socket = io("ws://192.168.1.8:2424/test_conn")
				//var _this = this
				this.socket.on('server_response', () => {
					console.log('Socket.IO连接已建立');
				});
				this.socket.on('server_response_law', message => {
					console.log(`收到消息server_response：${message}`);
					console.log(JSON.parse(message))
					var message1 = JSON.parse(message)
					if (message1.item_name == 'flwd') {
						//法律问答
						this.text_voice(message1.data)
					}
				});
				this.socket.on('juese_mes_mode', message => {
					clearInterval(this.timerId); // 清除计时器
					console.log(`收到消息server_response：${message}`);
					console.log(JSON.parse(message))
					var message1 = JSON.parse(message)
					if (message1.item_name == 'flwd') {
						//法律问答
						this.text_voice2(message1.data)
					}
				});
				//催债
				this.socket.on('message1', message => {
					console.log(`收到消息message1：${message}`);
					this.show = false
					this.current = 1
					var message2 = JSON.parse(message)
					console.log(message2)
					if (message2.item_name == 'answers') {
						if (message2.sign == 0) {
							this.all1 = {
								user: {
									name: 'coffce',
									img: require("../assets/image/touxiang2.png"),
								},
								sessions: {
									user: {
										name: '功能体验，请在APP端进行操作~',
										img: require("../assets/image/touxiang1.png"),
									},
									message: [{
										content: '',
										self: true,
										self1: false,
										dian: false,
										self2: false,
										date: this.time()
									}, ]
								}
							}
							this.all1.sessions.message.content = message2.data
							this.scrollToBottom()
						} else if (message2.sign == 1) {
							this.message_last = message2.data
							this.all1.sessions.message[0].content = message2.data
							this.scrollToBottom()
						} else if (message2.sign == 2) {
							this.text_voice1(this.message_last)
							//this.all1.sessions.message[this.all1.sessions.message.length-1].content = message2.data
						}
					}
				});
				//催债
				this.socket.on('message', message => {
					this.show = false
					console.log(`收到消息message：${message}`);
					var message2 = JSON.parse(message)
					console.log(message2)
					if (message2.item_name == 'answers') {
						if (message2.sign == 0) {
							var msg3 = {
								content: message2.data,
								// self: true,
								// self1: true,
								// dian: false,
								self: true,
								self1: false,
								dian: false,
								//date: this.time()
							}
							this.all1.sessions.message.push(msg3)
							this.scrollToBottom()
						} else if (message2.sign == 1) {
							this.all1.sessions.message[this.all1.sessions.message.length - 1].content = message2
								.data
							this.message_last = message2.data
							this.scrollToBottom()
						} else if (message2.sign == 2) {
							this.text_voice1(this.message_last)
							//this.all1.sessions.message[this.all1.sessions.message.length-1].content = message2.data
						}
						//this.dian = true
						// var msg4 = {
						// 	content: '...',
						// 	self: true,
						// 	self1: false,
						// 	dian: true,
						// }
						// this.all1.sessions.message.push(msg4)
						// this.scrollToBottom()
					}
				});
				this.socket.on('server_response', message => {
					console.log(`收到消息server_response：${message}`);
					console.log(JSON.parse(message))
					var message1 = JSON.parse(message)
					if (message1.item_name == 'huif') {
						this.list11 = JSON.parse(message1.data)
						this.scrollToBottom()
					}
				});
				this.socket.on('server_response_juxu', message => {
					console.log(`收到消息server_response_juxu：${message}`);
					console.log(JSON.parse(message))
					var message1 = JSON.parse(message)
					if (message1.item_name == 'huif') {
						if (message1.sign == 1) {
							var msg = {
								content: message1.data,
								self: true,
								self1: true,
								dian: false,
							}
							this.all1.sessions.message.push(msg)
							this.scrollToBottom()
						} else {
							console.log(JSON.parse(message1.data))
							this.list11 = JSON.parse(message1.data)
							this.scrollToBottom()
						}
					}
				});
				this.socket.on('message_law', message => {
					this.show = false
					this.current = 0
					console.log(`收到消息message：${message}`);
					var message2 = JSON.parse(message)
					console.log(message2)
					if (message2.item_name == 'flwd') {
						//bus.$emit("hello", '法律问答');
						this.current = 0
						var msg3 = {
							content: message2.data,
							self: true,
							self1: true,
							dian: false,
							self2: false,
							//date: this.time()
						}
						this.all.sessions.message.push(msg3)
						//this.dian = true
						var msg4 = {
							content: '...',
							self: true,
							self1: false,
							dian: true,
						}
						this.all.sessions.message.push(msg4)
						this.scrollToBottom()
					}
				});
				this.socket.on('juese_mes_user', message => {
					this.show = false
					this.current = 2
					console.log(`收到消息message：${message}`);
					var message2 = JSON.parse(message)
					console.log(message2)
					if (message2.item_name == 'flwd') {
						//bus.$emit("hello", '法律问答');
						var msg3 = {
							content: message2.data,
							self: true,
							self1: true,
							dian: false,
							self2: false,
							//date: this.time()
						}
						this.all2.sessions.message.push(msg3)
						//this.dian = true
						var msg4 = {
							content: '...',
							self: true,
							self1: false,
							dian: true,
						}
						this.all2.sessions.message.push(msg4)
						this.scrollToBottom()
					}
				});
			},
			websocketOpen() {
				console.log('websocket onopen', this.ws1.readyState);
			},
			websocketError() {
				console.log('websocket onerror', this.ws1.readyState);
			},
			error() {
				console.log('连接错误')
				console.log(this.ws1)
			},
			time() {
				//年
				let year = new Date().getFullYear();
				//月份是从0月开始获取的，所以要+1;
				let month = new Date().getMonth() + 1;
				//日
				let day = new Date().getDate();
				//时
				let hour = new Date().getHours();
				//分
				let minute = new Date().getMinutes();
				//秒
				let second = new Date().getSeconds();
				let time = year + '-' + month + '-' + day + ':' + hour + ':' + minute + ':' + second
				return time
			},
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Xiaomi|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
					)
				return flag;
			},
			dialogVisible1(e) {
				this.dialogVisible = true
				this.imageUrl = e
			},
			//自动滚动到最底部
			scrollToBottom() {
				if (this.$refs.QAContent == undefined) {} else {
					// this.$nextTick 将回调延迟到下次DOM更新循环之后执行。在修改数据之后立即使用它，然后等待DOM更新
					this.$nextTick(() => {
						console.log(this.$refs.QAContent)
						// dom 元素更新后执行滚动条到底部 否则不生效
						let scrollElem = this.$refs.QAContent;
						console.log('scrollHeight: ', scrollElem.scrollHeight);
						// scrollElem.scrollTop = scrollElem.scrollHeight
						scrollElem.scrollTo({
							top: scrollElem.scrollHeight,
							behavior: 'smooth'
						});
					});
				}
			},
		},
	}
</script>
<style lang="less" scoped>
	* {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.showbackground {
		width: 100vw;
		height: 100vh;
		background-image: url(../assets/image/wrap1.png);
		background-repeat: no-repeat;
		//保证盒子始终在窗口中心
		//background-position: center center;
	}

	.pc {
		width: 22vw;
		height: 100vh;
		background-image: url(../assets/image/index1.png);
		background-repeat: no-repeat;
		//保证盒子始终在窗口中心
		background-position: center center;
		text-align: center;
	}

	.message::-webkit-scrollbar {
		display: none;
	}

	.main {
		// display: flex;
		// justify-content: right;
		//align-items: center;
	}

	.bann-video {
		width: auto;
		height: 400px;
		//border: 1px red solid;
		//margin-bottom: -200px;
	}

	.message1 {
		width: 74vw;
		height: 87vh;
		//overflow-y: scroll;
		border-radius: 15px;
		box-sizing: border-box;
		background-color: #0E1340;
		padding: 10px 0px;
	}

	.message {
		width: 74vw;
		height: 85vh;
		//margin-top: 1vh;
		overflow-y: scroll;
		border-radius: 15px;
		box-sizing: border-box;
		background-color: #0E1340;
		padding: 0px 15px;

		//box-sizing: border-box;
		li {
			margin-bottom: 15px;
		}

		.time {
			margin: 13px 0;
			text-align: center;
			font-size: 10px;

			>span {
				display: inline-block;
				padding: 0 18px;
				font-size: 12px;
				color: #fff;
				border-radius: 2px;
				background-color: #dcdcdc;
			}
		}

		.avatar {
			float: left;
			margin: 0 10px 0 0;
			border-radius: 3px;
		}

		.text {
			display: inline-block;
			position: relative;
			padding: 10px 20px;
			// max-width: ~'calc(100% - 400px)';
			max-width: 66%;
			color: #fff;
			//min-height: 30px;
			//line-height: 2.5;
			font-size: 16px;
			text-align: left;
			word-break: break-all;
			background: linear-gradient(125deg, #24B4FF 0%, #A721FF 100%);
			//border-radius: 4px;
			border-bottom-right-radius: 20px;
			//border-bottom-left-radius: 20px;
			border-top-left-radius: 20px;
			border-top-right-radius: 20px;
			//right: 0;
			// &:before {
			// 	content: " ";
			// 	position: absolute;
			// 	top: 9px;
			// 	right: 100%;
			// 	border: 6px solid transparent;
			// 	border-right-color: #F1F5F8;
			// }
		}

		.self {
			display: flex;
			justify-content: flex-end;
			align-items: start;

			//border: 1px red solid;
			//width: 400px;
			//float: right;
			//text-align: right;
			.avatar {
				float: right;
				margin: 0 0 0 10px;
			}

			.text {
				float: right;
				background: linear-gradient(263deg, #FF9224 0%, #0166D8 100%);
				border-bottom-right-radius: 0px;
				border-bottom-left-radius: 20px;
				border-top-left-radius: 20px;
				border-top-right-radius: 20px;
				// &:before {
				// 	right: inherit;
				// 	left: 100%;
				// 	border-right-color: transparent;
				// 	border-left-color: #DEEEFF;
				// }
			}
		}
	}

	.qingkong {
		height: 45px;
		width: 147px;
		display: flex;
		align-items: center;
		background-image: url(../assets/image/tu.png);
		background-size: cover;
		background-repeat: no-repeat;
		justify-content: center;
		//text-align: center;
	}

	.el-dialog__body {
		text-align: center;
	}

	.avatar1 {
		width: auto;
		height: 500px;
		border-radius: 20px;
	}

	.avatar2 {
		width: 400px;
		height: 400px;
	}

	.dotting {
		display: inline-block;
		min-width: 4px;
		min-height: 4px;
		box-shadow: 4px 0 currentColor, 12px 0 currentColor, 20px 0 currentColor;
		/* for IE9+, ..., 3个点 */
		animation: dot 2s infinite step-start both;
		/* for IE10+, ... */
		//*zoom: expression(this.innerHTML = '...'); /*  for IE7. 若无需兼容IE7, 此行删除 */
	}

	.dotting:before {
		content: '...';
	}

	/* for IE8. 若无需兼容IE8, 此行以及下一行删除*/
	.dotting::before {
		content: '';
	}

	/* for IE9+ 覆盖 IE8 */
	:root .dotting {
		margin-right: 20px;
		margin-bottom: 5px;
	}

	/* for IE9+,FF,CH,OP,SF 占据空间*/
	@keyframes dot {
		25% {
			box-shadow: none;
		}

		/* 0个点 */
		50% {
			box-shadow: 4px 0 currentColor;
		}

		/* 1个点 */
		75% {
			box-shadow: 4px 0 currentColor, 12px 0 currentColor;
			/* 2个点 */
		}
	}
</style>