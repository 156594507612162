<template>
	<div>
		<div class="main">
			<Message></Message>
			<!-- <Texttable :all="all"></Texttable> -->
		</div>
		<!-- <div v-if="mobile" class="main1">
			<Message :all="all"></Message>
			<Texttable :all="all"></Texttable>
		</div> -->
	</div>
</template>

<script>
	//万物识别
	import Message from '../components/message.vue'
	//import Texttable from '../components/text.vue'
	export default {
		components: {
			Message,
			//Texttable
		},
		data() {
			return {
				mobile: '',
				list_id: '1',
				

			}
		},
		created() {

		},
		computed: {
			
		},
		mounted() {
			
		},
		methods: {
			
		},
	}
</script>

<style scoped>

		.main {
			
			/* // width: 100%;
			// height: 100%; */
			width: 76vw;
			height: 100vh;
			//border: 1px red solid;
			
		}
		.text {
			position: absolute;
			width: 100%;
			bottom: 0;
			left: 0;
		}
		
		/* // .message1 {
		// 	height: ~'calc(100% - 45vh)';
		// } */

</style>
